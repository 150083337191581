
import Avatar from "primevue/avatar";
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import { TranslateResult } from "vue-i18n";
import { Component, Prop, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import ProxyItem from "@/components/navigation/ProxyItem.vue";
import config from "@/config";
import { Assistant, IPerson, Representative, Role, UnauthorizedError } from "@/global";
import AccountModule from "@/store/modules/account";
import ConsentModule from "@/store/modules/consent";
import DocumentModule from "@/store/modules/document";
import SidebarModule from "@/store/modules/sidebar";

@Component({
  components: {
    Button,
    Avatar,
    Dropdown,
    ProxyItem,
  },
})
export default class ProxySelect extends Vue {
  @Prop({ default: false, type: Boolean }) hideAvatar!: boolean;

  private readonly accountState = getModule(AccountModule);
  private readonly documentState = getModule(DocumentModule);
  private readonly sidebarState = getModule(SidebarModule);
  private readonly consentState = getModule(ConsentModule);

  private wasSidebarOpen: boolean | null = null;

  isAssistant(person: any): person is Assistant {
    if (person.gln) return true;
    return false;
  }

  async selectedPerson(person: any) {
    // person: Representative | Assistant
    let roleToSwitchTo = Role.None;
    let proxyPerson!: IPerson;
    if (this.isAssistant(person)) {
      const practitioner = person;
      this.accountState.setPractitionerGLN(practitioner.gln);
      proxyPerson = {
        name: practitioner.firstName + " " + practitioner.lastName,
      };
      roleToSwitchTo = Role.Assistant;
      this.accountState.setRole(roleToSwitchTo);

      this.accountState.setProxyPerson(proxyPerson);
      return;
    } else {
      const representative = person as Representative;
      this.accountState.setPatientSPID(representative.spid);
      this.accountState.setPatientLocalID(
        config.assigningAuthorities.local + "|" + representative.uapId
      );
      proxyPerson = {
        name: representative.firstName + " " + representative.lastName,
      };
      roleToSwitchTo = Role.Representative;
    }

    this.accountState.clearXUAToken();

    this.accountState.setProxyPerson(proxyPerson);

    this.accountState
      .getAuthenticationHeader()
      .then(() => {
        this.accountState.setRole(roleToSwitchTo);
        this.accountState.setProxyPerson(proxyPerson);
        this.documentState.clearDocuments();
        if (this.$route.name == "timeline") {
          this.accountState.setNoAccessToThisEPD(false);
          this.documentState
            .fetchDocuments()
            .then(() => {
              this.accountState.setNoAccessToThisEPD(false);
              this.$notify({
                text: this.$t("toasts.documentStore.documentsFetchSuccess").toString(),
                type: "success",
                data: { srOnly: true },
              });
            })
            .catch((error) => {
              if (error instanceof UnauthorizedError) {
                this.accountState.setNoAccessToThisEPD(true);
              } else {
                this.$notify({
                  text: this.$t("toasts.documentStore.documentsFetchFail").toString(),
                  type: "error",
                  duration: -1,
                });

                this.accountState.setNoAccessToThisEPD(false);
              }
            });
        } else {
          this.$router.push({ name: "timeline", params: { _reload: "true" } });
        }
      })
      .catch(() => {
        this.accountState.clearXUAToken();
        this.accountState.clearProxyPerson();
        this.$notify({
          text: this.$t("toasts.account.xuaFetchFail").toString(),
          type: "error",
          duration: -1,
        });
      });
  }

  async logOutProxy() {
    if (this.documentState.isLoading) {
      return;
    }

    this.documentState.clearDocuments();
    this.consentState.clearConsent();
    switch (this.accountState.role) {
      case Role.Patient:
      case Role.Representative:
        // Fall back to IDP Token? Or use the XUA token for the patient?
        this.accountState.clearXUAToken();
        this.accountState.clearPatientSPID();
        this.accountState.clearPatientLocalID();
        this.accountState.clearProxyPerson();
        this.accountState.setRole(Role.Patient);
        if (this.$route.name == "timeline") {
          this.accountState.setNoAccessToThisEPD(false);
          this.documentState
            .fetchDocuments()
            .then(() => {
              this.accountState.setNoAccessToThisEPD(false);
              this.$notify({
                text: this.$t("toasts.documentStore.documentsFetchSuccess").toString(),
                type: "success",
                data: { srOnly: true },
              });
            })
            .catch((error) => {
              if (error instanceof UnauthorizedError) {
                this.accountState.setNoAccessToThisEPD(true);
              } else {
                this.accountState.setNoAccessToThisEPD(false);

                this.$notify({
                  text: this.$t("toasts.documentStore.documentsFetchFail").toString(),
                  type: "error",
                  duration: -1,
                });
              }
            });
        } else {
          this.$router.push({ name: "timeline", params: { _reload: "true" } });
        }
        break;
      case Role.HealthcareProfessional:
      case Role.Assistant:
        // Go back to patient Search? Or
        this.accountState.clearProxyPerson();
        this.accountState.clearXUAToken();
        this.accountState.setRole(Role.HealthcareProfessional);
        this.accountState.logOutPatient();
        if (this.$route.name != "patients") {
          this.$router.push({ name: "patients" });
        }
        break;
    }
    this.accountState.fetchProxies();
  }

  get showProxySelection(): boolean {
    if (this.hideAvatar) {
      return false;
    }

    switch (this.accountState.role) {
      case Role.HealthcareProfessional:
        if (this.accountState.selectedPatient) {
          return false;
        } else {
          return true;
        }
      case Role.Patient:
        return true;
    }
    return false;
  }

  handleSelect(event: { originalEvent: Event; value: Representative | Assistant }) {
    this.sidebarState.setIsSidebarOpen(this.wasSidebarOpen || false);
    this.wasSidebarOpen = null;
    this.selectedPerson(event.value);
  }

  handleClick(person: Representative | Assistant) {
    this.sidebarState.setIsSidebarOpen(this.wasSidebarOpen || false);
    this.wasSidebarOpen = null;
    this.selectedPerson(person);
  }

  handleShow() {
    if (this.hideAvatar) return;

    this.wasSidebarOpen = this.sidebarState.isSidebarOpen;
    this.sidebarState.setIsSidebarOpen(true);
  }

  handleHide() {
    if (this.hideAvatar) return;

    this.sidebarState.setIsSidebarOpen(this.wasSidebarOpen || false);
    this.wasSidebarOpen = null;
    (document.activeElement as HTMLElement | null)?.blur();
  }

  get highlight(): boolean {
    return !!this.accountState.proxies?.length && this.accountState.role === Role.Assistant;
  }

  get placeholder(): TranslateResult {
    switch (this.accountState.role) {
      case Role.Patient:
      case Role.Representative:
        return this.$t("navigation.proxies.startRepresentation");
      case Role.HealthcareProfessional:
      case Role.Assistant:
        return this.$t("navigation.proxies.startAssistance");
      default:
        return "";
    }
  }

  get noPersonsConfigured(): TranslateResult {
    switch (this.accountState.role) {
      case Role.Patient:
      case Role.Representative:
        return this.$t("navigation.proxies.noRepresentatives");
      case Role.HealthcareProfessional:
      case Role.Assistant:
        return this.$t("navigation.proxies.noAssistants");
      default:
        return this.$t("navigation.proxies.noRepresentatives");
    }
  }

  get activeProxyLabel(): TranslateResult {
    switch (this.accountState.role) {
      case Role.HealthcareProfessional:
      case Role.Assistant:
        return this.$t("navigation.proxies.assisting");
      case Role.Patient:
      case Role.Representative:
        return this.$t("navigation.proxies.representing");
      default:
        return "";
    }
  }

  get logoutText(): TranslateResult {
    switch (this.accountState.role) {
      case Role.HealthcareProfessional:
      case Role.Assistant:
        return this.$t("navigation.proxies.stopAssistance");
      case Role.Patient:
      case Role.Representative:
        return this.$t("navigation.proxies.stopRepresentation");
      default:
        return "";
    }
  }

  get minimized() {
    return !this.hideAvatar && !this.sidebarState.isSidebarOpen;
  }
}
