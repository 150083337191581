
import Divider from "primevue/divider";
import { Component, Prop, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import { ActionTileState, Assistant, IPatient, IPerson, Representative, Role } from "@/global";
import SidebarModule from "@/store/modules/sidebar";

import ActiveUser from "./ActiveUser.vue";
import MenuLinks from "./MenuLinks.vue";
import ProxySelect from "./ProxySelect.vue";

@Component({
  components: {
    MenuLinks,
    ActiveUser,
    ProxySelect,
    Divider,
  },
})
export default class PureMenuContent extends Vue {
  @Prop({ default: null }) readonly currentUser!: IPerson | null;
  @Prop({ default: null }) readonly proxy!: (Representative | Assistant) | null;
  @Prop({ default: Role.None }) readonly role!: Role;
  @Prop({ default: null }) readonly patient?: IPatient;
  @Prop({ default: [] }) private readonly loggedInUserRoles!: string[];

  readonly actionTileState!: ActionTileState;
  readonly sidebarState: SidebarModule = getModule(SidebarModule);

  get isHCP(): boolean {
    switch (this.role) {
      case Role.HealthcareProfessional:
      case Role.Assistant:
        return true;
      default:
        return false;
    }
  }
}
