
import { Component, Vue } from "vue-property-decorator";

import config from "@/config";

@Component
export default class AppIcon extends Vue {
  get altText() {
    return `${config.homeCommunityName} icon`;
  }

  get path() {
    return `/icons/favicon.svg`;
  }
}
