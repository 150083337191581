
import { PrimeVueLocaleOptions } from "primevue/config";
import Dropdown from "primevue/dropdown";
import { Component, Prop, Vue } from "vue-property-decorator";

import config from "@/config";

@Component({ components: { Dropdown } })
export default class LanguageSelect extends Vue {
  @Prop({ default: false, type: Boolean }) private readonly withIcon!: boolean;
  @Prop({ default: false, type: Boolean }) private readonly minimized!: boolean;

  getShortLabel(code: string) {
    return this.$t(`languages.short.${code}`);
  }

  getLongLabel(code: string) {
    return this.$t(`languages.long.${code}`);
  }

  get availableLanguages(): string[] {
    return config.availableLanguages;
  }

  get currentLanguage(): string {
    if (!this.availableLanguages.includes(this.$i18n.locale)) {
      this.$i18n.locale = this.$i18n.fallbackLocale.toString();
    }
    return this.$i18n.locale;
  }

  set currentLanguage(languageCode: string) {
    this.$i18n.locale = languageCode;
    const shortLocale = this.$i18n.locale.split("-")[0];
    if ((window as any).mtcaptchaConfig) {
      (window as any).mtcaptchaConfig.lang = shortLocale;
    }

    const html = document.documentElement; // returns the html tag
    html.setAttribute("lang", shortLocale);

    window.localStorage.setItem("phellow:language", this.$i18n.locale);
    this.$primevue.config.locale = this.$i18n.messages[this.$i18n.locale]
      .global as unknown as PrimeVueLocaleOptions;

    if (this.$route.name == "legal" || this.$route.name == "privacy") {
      this.$router.replace({
        name: this.$route.name!,
        query: { language: shortLocale },
      });
    }
  }
}
