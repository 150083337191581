
import Button from "primevue/button";
import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import BackButton from "@/components/BackButton.vue";
import ProxySelect from "@/components/navigation/ProxySelect.vue";
import SidebarModule from "@/store/modules/sidebar";

@Component({
  components: { Button, BackButton, ProxySelect },
})
export default class Navbar extends Vue {
  private readonly sidebarState: SidebarModule = getModule(SidebarModule);
  private isSmallScreen: boolean = false;

  created() {
    window.addEventListener("resize", this.handleResize);
    this.isSmallScreen = window.innerWidth < 768;
  }

  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize(event: any) {
    this.isSmallScreen = event.target.innerWidth < 768;
  }

  get isLoginPage(): boolean {
    return this.$route.path.startsWith("/login");
  }

  get isNestedPage(): boolean {
    return this.$route.path.replace(/\/*$/g, "").lastIndexOf("/") > 0;
  }

  setSidebarOpen(actualState: boolean) {
    this.sidebarState.setIsSidebarOpen(actualState);
  }
}
