import { Role } from "./global";

declare global {
  interface Window {
    __env__: Record<any, string | undefined>;
  }
}

window.__env__ = process.env || window.__env__ || {};

const meta = document.getElementsByTagName("meta");
for (let i = 0; i < meta.length; i++) {
  const element = meta.item(i);
  if (element?.getAttribute("data-name") == "env") {
    window.__env__ = Object.assign(
      window.__env__,
      JSON.parse(element.getAttribute("data-content")!)
    );
  }
}

const APHINITY: string =
  window.__env__.VUE_APP_API_APHINITY || "https://api.portals.test.bint.ch/ad/";
const EPD_SERVICES: string =
  window.__env__.VUE_APP_API_EPD_SERVICES || "https://api.portals.test.bint.ch/epr/";

export default {
  epdCertified: JSON.parse(window.__env__.VUE_APP_EPD_CERTIFIED || "false"),
  i18n: {
    override: JSON.parse(window.__env__.VUE_APP_I18N_OVERRIDE || "{}"),
  },
  feature: {
    vaccination: JSON.parse(window.__env__.VUE_APP_FEATURE_VACCINATION || "true"),
  },
  session: {
    disableSessionConstraints: JSON.parse(
      window.__env__.VUE_APP_SESSION_DISABLE_CONTRAINTS || "false"
    ),
    idleTimeout: +(window.__env__.VUE_APP_SESSION_IDLE_TIMEOUT || 15 * 60),
    maxAge: +(window.__env__.VUE_APP_SESSION_MAX_AGE || 60 * 60),
    honorIDTokenExpiration: JSON.parse(window.__env__.VUE_APP_SESSION_HONOR_ID_TOKEN_EXP || "true"),
    documentLimit: +(window.__env__.VUE_APP_SESSION_DOCUMENT_LIMIT || 2),
  },
  openid: {
    authEndpoint:
      window.__env__.VUE_APP_OAUTH2_AUTH ||
      "https://account.epd.phellowseven.io/tenant/ehealthsuisse.ihe-europe.net/openid-connect/auth",
    tokenEndpoint:
      window.__env__.VUE_APP_OAUTH2_TOKEN ||
      "https://account.epd.phellowseven.io/tenant/ehealthsuisse.ihe-europe.net/openid-connect/token",
    logoutEndpoint:
      window.__env__.VUE_APP_OAUTH2_LOGOUT ||
      "https://account.epd.phellowseven.io/tenant/ehealthsuisse.ihe-europe.net/openid-connect/logout",
    sessionEndpoint:
      window.__env__.VUE_APP_OAUTH2_SESSION ||
      "https://account.epd.phellowseven.io/tenant/ehealthsuisse.ihe-europe.net/session",
    clientId: window.__env__.VUE_APP_OAUTH2_CLIENT_ID || "phellow-web",
    redirectUri: window.__env__.VUE_APP_OAUTH2_REDIRECT_URI || "http://localhost:8080/login",
    scopes: (window.__env__.VUE_APP_OAUTH2_SCOPES || "").split(" "),
    pkce: JSON.parse(window.__env__.VUE_APP_OAUTH2_PKCE || "false"),
    prompt: window.__env__.VUE_APP_OPENID_PROMPT || null,
  },
  domain: window.__env__.VUE_APP_APHINITY_DOMAIN || "epdpro_bint",
  stu3: {
    documents: {
      referenceEndpoint: APHINITY + "stu3/DocumentReference",
    },
    binary: {
      downloadZip: APHINITY + "stu3/Binary/$zip",
    },
  },
  r4: {
    documents: {
      referenceEndpoint: APHINITY + "r4/DocumentReference",
      upload: APHINITY + "r4/",
    },
    binary: {
      downloadZip: APHINITY + "r4/Binary/$zip",
    },
    practitionerRole: APHINITY + "r4/PractitionerRole",
    organization: APHINITY + "r4/Organization",
    consent: APHINITY + "r4/Consent",
    auditEvent: APHINITY + "r4/AuditEvent",
    patients: APHINITY + "r4/Patient",
    pixm: APHINITY + "r4/Patient/$ihe-pix",
  },
  epdServices: {
    v1: {
      assistedPerson: EPD_SERVICES + "v1/AssistedPerson",
      representedPerson: EPD_SERVICES + "v1/RepresentedPerson",
      parentOrganization: EPD_SERVICES + "v1/ParentOrganization",
      notification: EPD_SERVICES + "v1/Notification",
      conversion: EPD_SERVICES + "v1/Conversion",
      vaccinationRequest: EPD_SERVICES + "v1/VaccinationRequest",
      settings: {
        notifications: EPD_SERVICES + "v1/Setting/Notifications",
      },
    },
  },
  homeCommunityID: window.__env__.VUE_APP_HOME_COMMUNITY_ID || "1.3.6.1.4.1.21367.2017.2.5.69",
  homeCommunityName: window.__env__.VUE_APP_HOME_COMMUNITY_NAME || "Abilis",
  homeCommunityPrefix: window.__env__.VUE_APP_HOME_COMMUNITY_PREFIX || "Abilis",
  assigningAuthorities: {
    spid: "2.16.756.5.30.1.127.3.10.3",
    local: window.__env__.VUE_APP_LOCAL_AA || "1.3.6.1.4.1.21367.2017.2.11.34",
    search: window.__env__.VUE_APP_SEARCH_AA || "2.16.756.5.30.1.127.3.10.3",
  },
  documents: {
    filter: {
      upload: ((window.__env__.VUE_APP_DOCUMENT_FILTER_UPLOAD as string) || "pdf-a").split(","),
    },
  },
  role: window.__env__.VUE_APP_MODE == "HCP" ? Role.HealthcareProfessional : Role.Patient,
  submissionSet: { authorRoleSystem: "1.3.6.1.4.1.21367.2017.2.5.63" },
  availableLanguages: ["de-ch", "fr-ch", "it-ch", "en-us"],
  mtcaptchaConfig: {
    sitekey: window.__env__.VUE_APP_MTCAPTCHA_SITE_KEY || "MTPublic-69flufdXi",
    widgetSize: "mini",
    render: "explicit", // necessary to load when Vue-Component gets created. Was done this way in MTCaptcha Vue Demo.
    lang: "de", // default lang
    theme: "neowhite",
    renderQueue: [],
    autoFormValidate: true,
    enableTestMode: window.__env__.VUE_APP_MTCAPTCHA_TEST_KEY || null,
  },
  debug: {
    staticSPID: window.__env__.VUE_APP_DEBUG_STATIC_SPID || null,
    disableRoleCheck: JSON.parse(window.__env__.VUE_APP_DEBUG_DISABLE_ROLE_CHECK || "false"),
    commitHash: window.__env__.VUE_APP_DEBUG_COMMIT_HASH || "",
    versionTag: window.__env__.VUE_APP_DEBUG_VERSION_TAG || "",
  },
};
