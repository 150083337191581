
import Button from "primevue/button";
import ScrollPanel from "primevue/scrollpanel";
import { ComponentPublicInstance } from "vue";
import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import SyringeIcon from "@/components/icons/SyringeIcon.vue";
import config from "@/config";
import { ActionTileState, Role } from "@/global";
import AccountModule from "@/store/modules/account";
import SidebarModule from "@/store/modules/sidebar";
import ViewModule from "@/store/modules/view";

@Component({
  components: {
    SyringeIcon,
    Button,
    ScrollPanel,
  },
})
export default class MenuLinks extends Vue {
  @Prop({ default: Role.None }) readonly role!: Role;
  @Prop({ default: [] }) private readonly loggedInUserRoles!: string[];
  @Prop({ default: false }) private readonly patientSelected!: boolean;

  @Ref("menuLinksWrapper") private menuLinksWrapper!: HTMLElement;
  @Ref("scrollPanel") private scrollPanel!: ComponentPublicInstance;

  private activeGroup: number = 0;
  private scrollHeight: number | null = null;

  readonly accountState: AccountModule = getModule(AccountModule);
  readonly sidebarState: SidebarModule = getModule(SidebarModule);
  readonly viewState: ViewModule = getModule(ViewModule);

  ActionTileState: object = {
    Documents: ActionTileState.Documents,
    DocumentAccess: ActionTileState.DocumentAccess,
    Settings: ActionTileState.Settings,
    AccessLogs: ActionTileState.AccessLogs,
    Vaccination: ActionTileState.Vaccination,
    None: ActionTileState.None,
  };

  mounted() {
    if (this.menuLinksWrapper) {
      this.scrollHeight = this.menuLinksWrapper.scrollHeight;
    }
  }

  async openVaccinationModule() {
    let body = {
      firstName:
        this.accountState.parsedIDToken?.given_name || this.accountState.parsedIDToken?.sub,
      lastName:
        this.accountState.parsedIDToken?.family_name || this.accountState.parsedIDToken?.sub,

      language: this.$i18n.locale.replace("-", "_"),
      role: this.accountState.role,
      purpose: this.accountState.hasEmergencyAccess ? "EMER" : "NORM",
    };

    if (
      this.accountState.role == Role.HealthcareProfessional ||
      this.accountState.role == Role.Assistant
    ) {
      body = Object.assign(body, {
        gln: this.accountState.loggedInUserGLN,
      });
    }

    if (this.accountState.role == Role.Assistant) {
      body = Object.assign(body, {
        principalId: this.accountState.practitionerGLN,
        principalName: this.accountState.proxyPerson?.name,
      });
    }

    if (this.accountState.patientLocalID) {
      const [authority, value] = this.accountState.patientLocalID.split("|");
      body = Object.assign(body, {
        patientId: {
          value,
          authority,
        },
      });
    }

    try {
      const response = await fetch(config.epdServices.v1.vaccinationRequest, {
        method: "POST",
        headers: {
          authorization: await this.accountState._getAuthenticationHeader(true),
          "content-type": "application/json",
        },
        body: JSON.stringify(body),
      });

      const url = await response.text();
      window.open(url, "_blank");
    } catch (e) {
      this.$notify({
        text: this.$t("toasts.vaccination.unableToLoad").toString(),
        type: "error",
        duration: -1,
      });
    }
  }

  toggleGroup(index: number) {
    if (this.activeGroup === index) {
      this.activeGroup = -1;
    } else {
      this.activeGroup = index;
    }
    this.$nextTick(() => {
      if (this.scrollPanel?.$el?.children[0]?.children[0]) {
        this.scrollPanel.$el.children[0].children[0].scrollTop = +this.scrollHeight! * 3;
      }
    });
  }

  get helpLink(): string {
    return this.$t(`community.url.help${config.role}`).toString();
  }

  get isProfessionalUser(): boolean {
    return this.role == Role.HealthcareProfessional || this.role == Role.Assistant;
  }

  // Currently always hidden until there's a second menu item before selecting a patient
  get showPatientSearch(): boolean {
    return this.isProfessionalUser && !this.patientSelected && false;
  }

  get showDocuments(): boolean {
    return this.patientSelected || this.role == Role.Patient || this.role == Role.Representative;
  }

  get showSettings(): boolean {
    return (
      (this.role == Role.Patient || this.role == Role.Representative) &&
      this.loggedInUserRoles.includes(Role.Patient)
    );
  }

  get showDocumentAccess(): boolean {
    return (
      (this.role == Role.Patient || this.role == Role.Representative) &&
      this.loggedInUserRoles.includes(Role.Patient)
    );
  }

  get showAccessLogs(): boolean {
    return (
      (this.role == Role.Patient || this.role == Role.Representative) &&
      this.loggedInUserRoles.includes(Role.Patient)
    );
  }

  get showVaccination(): boolean {
    return (
      this.role == Role.Patient ||
      this.role == Role.Representative ||
      (config.feature.vaccination && this.patientSelected)
    );
  }

  handleClick(): void {
    if (window.innerWidth < 768) {
      this.sidebarState.setIsSidebarOpen(false);
    }
  }
}
