
import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import { ActionTileState } from "@/global";
import AccountModule from "@/store/modules/account";

import PureMenuContent from "./PureMenuContent.vue";

@Component({
  components: {
    PureMenuContent,
  },
})
export default class MenuContent extends Vue {
  private accountState = getModule(AccountModule);

  created() {
    this.fetchProxies();
  }

  activated() {
    this.fetchProxies();
  }

  get actionTileState(): ActionTileState {
    if (this.$route.meta?.tile == "timeline") {
      return ActionTileState.Documents;
    } else if (this.$route.meta?.tile == "documentAccess") {
      return ActionTileState.DocumentAccess;
    } else if (this.$route.meta?.tile == "userSettings") {
      return ActionTileState.Settings;
    } else if (this.$route.meta?.tile == "accessLogs") {
      return ActionTileState.AccessLogs;
    } else if (this.$route.meta?.tile == "vaccination") {
      return ActionTileState.Vaccination;
    }

    return ActionTileState.None;
  }

  async fetchProxies() {
    const failureMessage = await this.accountState.fetchProxies({ autoRepresent: true });

    if (failureMessage) {
      this.$notify({
        text: this.$t(failureMessage).toString(),
        type: "error",
        duration: -1,
      });
    }
  }
}
