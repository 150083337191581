import { render, staticRenderFns } from "./ProxyItem.vue?vue&type=template&id=44ae8b59&scoped=true"
import script from "./ProxyItem.vue?vue&type=script&lang=ts"
export * from "./ProxyItem.vue?vue&type=script&lang=ts"
import style0 from "./ProxyItem.vue?vue&type=style&index=0&id=44ae8b59&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44ae8b59",
  null
  
)

export default component.exports