import {
  AppAuthError,
  AuthorizationNotifier,
  AuthorizationRequest,
  AuthorizationRequestHandler,
  AuthorizationServiceConfiguration,
  BaseTokenRequestHandler,
  BasicQueryStringUtils,
  DefaultCrypto,
  FetchRequestor,
  GRANT_TYPE_AUTHORIZATION_CODE,
  GRANT_TYPE_REFRESH_TOKEN,
  LocalStorageBackend,
  LocationLike,
  RedirectRequestHandler,
  StringMap,
  TokenRequest,
  TokenRequestHandler,
  TokenResponse,
} from "@openid/appauth";

import config from "./config";

class NoHashQueryStringUtils extends BasicQueryStringUtils {
  parse(input: LocationLike) {
    return super.parse(input, false);
  }
}

class AppAuth {
  private configuration: AuthorizationServiceConfiguration;
  private notifier: AuthorizationNotifier;
  private authorizationHandler: AuthorizationRequestHandler;
  private tokenHandler: TokenRequestHandler;

  constructor() {
    this.configuration = new AuthorizationServiceConfiguration({
      authorization_endpoint: config.openid.authEndpoint,
      token_endpoint: config.openid.tokenEndpoint,
      revocation_endpoint: "",
    });

    this.notifier = new AuthorizationNotifier();
    this.authorizationHandler = new RedirectRequestHandler(
      new LocalStorageBackend(),
      new NoHashQueryStringUtils(),
      window.location,
      new DefaultCrypto()
    );
    this.tokenHandler = new BaseTokenRequestHandler(
      new FetchRequestor(),
      new NoHashQueryStringUtils()
    );

    this.authorizationHandler.setAuthorizationNotifier(this.notifier);
  }

  async refresh(refreshToken: string): Promise<TokenResponse> {
    const request = new TokenRequest({
      client_id: config.openid.clientId,
      redirect_uri: config.openid.redirectUri,
      grant_type: GRANT_TYPE_REFRESH_TOKEN,
      refresh_token: refreshToken,
    });

    try {
      return await this.tokenHandler.performTokenRequest(this.configuration, request);
    } catch (e) {
      if (e instanceof AppAuthError && e.message == "400")
        window.dispatchEvent(new CustomEvent("phellow:event:logout"));

      throw e;
    }
  }

  authorizationRequest() {
    const request = new AuthorizationRequest(
      {
        client_id: config.openid.clientId,
        redirect_uri: config.openid.redirectUri,
        scope: config.openid.scopes.join(" "),
        response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
        extras: config.openid.prompt ? { prompt: config.openid.prompt } : {},
      },
      new DefaultCrypto(),
      config.openid.pkce
    );

    return this.authorizationHandler.performAuthorizationRequest(this.configuration, request);
  }

  async completeIfPossible(): Promise<TokenResponse> {
    return new Promise((resolve, reject) => {
      this.notifier.setAuthorizationListener((request, response, error) => {
        if (error) {
          reject(error);
        }

        this.codeExchange(response!!.code, request?.internal?.code_verifier)
          .then(resolve)
          .catch(reject);
      });

      this.authorizationHandler.completeAuthorizationRequestIfPossible();
    });
  }

  private async codeExchange(code: string, codeVerifier?: string): Promise<TokenResponse> {
    const extras: StringMap = {};

    if (codeVerifier) {
      extras.code_verifier = codeVerifier;
    }

    const request = new TokenRequest({
      client_id: config.openid.clientId,
      redirect_uri: config.openid.redirectUri,
      grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
      code,
      extras,
    });

    return this.tokenHandler.performTokenRequest(this.configuration, request);
  }
}

export default new AppAuth();
