
import Avatar from "primevue/avatar";
import { Component, Prop, Vue } from "vue-property-decorator";

import { Assistant, IPerson, Representative } from "@/global";
import { getInitials, getShortName } from "@/utils";

@Component({
  components: {
    Avatar,
  },
})
export default class ProxyItem extends Vue {
  @Prop() private readonly proxy!: Representative | Assistant | IPerson;
  @Prop() private readonly label!: string;
  @Prop({ default: false, type: Boolean }) private readonly active!: boolean;
  @Prop({ default: false, type: Boolean }) private readonly minimized!: boolean;
  @Prop({ default: false, type: Boolean }) private readonly hideAvatar!: boolean;

  get avatarClass(): string | null {
    if (!this.proxy) {
      return "!text-lg !text-neutral-600";
    }

    if (this.active) {
      return "!text-base !bg-opacity-100 !text-white animate-pulse";
    }

    return "!text-base";
  }

  get icon(): string | null {
    return this.proxy ? null : "pi pi-user";
  }

  get fullName(): string {
    if (!this.proxy) return "";
    if (Object.hasOwn(this.proxy, "firstName")) {
      const proxy = this.proxy as Representative | Assistant;
      return `${proxy.firstName} ${proxy.lastName}`;
    }

    const proxy = this.proxy as IPerson;
    return proxy.name;
  }

  get initials(): string | null {
    return this.proxy ? getInitials(this.fullName) : null;
  }

  get shortName(): string | null {
    return this.proxy ? getShortName(this.fullName, 30) : null;
  }

  get defaultSlotContent(): string | null {
    return this.shortName;
  }
}
