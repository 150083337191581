import { R4 } from "@ahryman40k/ts-fhir-types";
import { Dayjs } from "dayjs";

import { IDocumentReferenceExtended } from "@/store/modules/document";

export enum ConfidentialityCode {
  Normal = "17621005",
  Restricted = "263856008",
  Secret = "1141000195107",
}

export enum DocumentMetadataState {
  None,
  Upload,
  NewVersion,
  EditMetadata,
}

export enum Role {
  Patient = "PAT",
  Representative = "REP",
  HealthcareProfessional = "HCP",
  Assistant = "ASS",
  None = "NONE",
}

export enum ActionTileState {
  Documents,
  DocumentAccess,
  Settings,
  AccessLogs,
  Vaccination,
  None,
}

export enum SortBy {
  Author = "author",
  Date = "indexed",
  Title = "description",
  ConfidentialityCode = "confidentialityCode",
  Relationship = "relationship",
}

export enum DeletionStatus {
  DeletionNotRequested = "deletionNotRequested",
  DeletionRequested = "deletionRequested",
  DeletionProhibited = "deletionProhibited",
}

export interface IPerson {
  name: string;
}

export class Person implements IPerson {
  public name: string;

  constructor(name: string) {
    this.name = name;
  }
}

export interface Coding {
  code: string;
  system: string;
  display: string;
}

export interface Representative {
  firstName: string;
  lastName: String;
  spid: string;
  uapId: string;
}

// TODO: check the places where this type is used and find a proper naming for it
export interface Assistant {
  firstName: string;
  lastName: String;
  gln: string;
  uapId: string;
}

export class UnauthorizedError extends Error {
  constructor(message?: string) {
    super(message);
    // see: typescriptlang.org/docs/handbook/release-notes/typescript-2-2.html
    Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
    this.name = UnauthorizedError.name; // stack traces display correctly now
  }
}

export class ListFetchError extends Error {
  constructor(message?: string) {
    super(message);
    // see: typescriptlang.org/docs/handbook/release-notes/typescript-2-2.html
    Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
    this.name = ListFetchError.name; // stack traces display correctly now
  }
}

export interface IDocumentAccessItem {
  id?: string;
  person: IPerson;
  exipresAt: Dayjs;
  isOrganization: boolean;
  canDelegate: boolean;
  address: string;
}

export class DocumentAccessItem implements IDocumentAccessItem {
  public id?: string;
  public person: IPerson;
  public exipresAt: Dayjs;
  public isOrganization: boolean;
  public canDelegate: boolean;
  public address: string;

  constructor(
    person: IPerson,
    expiresAt: Dayjs,
    isOrganization: boolean,
    canDelegate: boolean,
    address: string,
    id?: string
  ) {
    this.person = person;
    this.exipresAt = expiresAt;
    this.isOrganization = isOrganization;
    this.canDelegate = canDelegate;
    this.address = address;
    this.id = id;
  }
}

export interface Document {
  metadata: R4.IDocumentReference;
  content: Blob | null;
}

export type Upload = Array<Document>;

/* export interface Upload {
  docRef: R4.IDocumentReference;
  blob: Array<{ id: string; blob: Blob }>;
} */

export interface IPatient extends IPerson {
  dateOfBirth: Dayjs | null;
  gender: R4.PatientGenderKind | null;
}

export function getIdentifier(
  identifiers: R4.IIdentifier[],
  authority: string | string[]
): { value: string; system: string } | null {
  const authorities = Array.isArray(authority) ? authority : [authority];

  for (const authority of authorities) {
    const identifier = identifiers?.find((identifier) => identifier.system == authority);
    if (identifier) {
      return { value: identifier.value!, system: identifier.system! };
    }
  }

  return null;
}

export enum MimeType {
  ApplicationDICOM = "application/dicom",
  ApplicationFHIRJSON = "application/fhir+json",
  ApplicationFHIRXML = "application/fhir+xml",
  ApplicationPDF = "application/pdf",
  AudioMP4 = "audio/mp4",
  AudioMPEG = "audio/mpeg",
  ImageGIF = "image/gif",
  ImageJP2 = "image/jp2",
  ImageJPEG = "image/jpeg",
  ImageJPM = "image/jpm",
  ImageJPX = "image/jpx",
  ImagePNG = "image/png",
  ImageTIFF = "image/tiff",
  ModelVRML = "model/vrml",
  ModelX3DXML = "model/x3d+xml",
  ModelX3DVRML = "model/x3d-vrml",
  MultipartMultilingual = "multipart/multilingual",
  MultipartSigned = "multipart/signed",
  MultipartXHL7CDALevel1 = "multipart/x-hl7-cda-level1",
  TextCSV = "text/csv",
  TextPlain = "text/plain",
  TextXML = "text/xml",
  VideoMJ2 = "video/mj2",
  VideoMP4 = "video/mp4",
  VideoMPEG = "video/mpeg",
}

export enum FormatCode {
  UnstructuredDocument = "urn:che:epr:EPR_Unstructured_Document",
  DICOMKOSSOPClassUID = "1.2.840.10008.5.1.4.1.1.88.59",
  XDSICDAWrappedTextReport = "urn:ihe:rad:TEXT",
  XDSIPDF = "urn:ihe:rad:PDF",
  XDSIImagingReportWithStructuredHeadings = "urn:ihe:rad:CDA:ImagingReportStructuredHeadings:2013",
  ScannedDocumentsPDF = "urn:ihe:iti:xds-sd:pdf:2008",
  ScannedDocumentsText = "urn:ihe:iti:xds-sd:text:2008",
  CommunityMedicationList = "urn:ihe:pharm:pml:2013",
  CommunityPrescription = "urn:ihe:pharm:pre:2010",
  CommunityDispense = "urn:ihe:pharm:dis:2010",
  CommunityMedicationTreatmentPlan = "urn:ihe:pharm:mtp:2015",
  CommunityPharmaceuticalAdvice = "urn:ihe:pharm:padv:2010",
  CDALaboratoryReport = "urn:ihe:lab:xd-lab:2008",
  ImmunizationContent = "urn:ihe:pcc:ic:2009",
  MimeTypeSufficient = "urn:ihe:iti:xds:2017:mimeTypeSufficient",
  MedicationCard = "urn:che:epr:ch-emed:medication-card:2023",
  ImmunizationAdministration = "urn:che:epr:ch-vacd:immunization-administration:2022",
  VaccinationRecord = "urn:che:epr:ch-vacd:vaccination-record:2022",
}

export interface JWT {
  /** Issuer */
  iss: string;

  /** Subject
   * Identifier for the End-User at the Issuer.
   */
  sub?: string;

  /** Audience */
  aud?: string | string[];

  /** Expiration Time */
  exp?: number;

  /** Not Before */
  nbf?: number;

  /** Issued At */
  iat?: number;

  /** JWT ID */
  jti?: string;
}

export interface CustomClaims {
  roles?: string[];
}

export interface IDToken extends JWT, CustomClaims {
  /**	End-User's full name in displayable form including all name parts, possibly including titles and suffixes, ordered according to the End-User's locale and preferences. */
  name?: string;
  /**	Given name(s) or first name(s) of the End-User. Note that in some cultures, people can have multiple given names; all can be present, with the names being separated by space characters. */
  given_name?: string;
  /**	Surname(s) or last name(s) of the End-User. Note that in some cultures, people can have multiple family names or no family name; all can be present, with the names being separated by space characters. */
  family_name?: string;
  /**	Middle name(s) of the End-User. Note that in some cultures, people can have multiple middle names; all can be present, with the names being separated by space characters. Also note that in some cultures, middle names are not used. */
  middle_name?: string;
  /**	Casual name of the End-User that may or may not be the same as the given_name. For instance, a nickname value of Mike might be returned alongside a given_name value of Michael. */
  nickname?: string;
  /**	Shorthand name by which the End-User wishes to be referred to at the RP, such as janedoe or j.doe. This value MAY be any valid JSON string including special characters such as @, /, or whitespace. The RP MUST NOT rely upon this value being unique, as discussed in Section 5.7. */
  preferred_username?: string;
  /**	URL of the End-User's profile page. The contents of this Web page SHOULD be about the End-User. */
  profile?: string;
  /**	URL of the End-User's profile picture. This URL MUST refer to an image file (for example, a PNG, JPEG, or GIF image file), rather than to a Web page containing an image. Note that this URL SHOULD specifically reference a profile photo of the End-User suitable for displaying when describing the End-User, rather than an arbitrary photo taken by the End-User. */
  picture?: string;
  /**	URL of the End-User's Web page or blog. This Web page SHOULD contain information published by the End-User or an organization that the End-User is affiliated with. */
  website?: string;
  /**	End-User's preferred e-mail address. Its value MUST conform to the RFC 5322 [RFC5322] addr-spec syntax. The RP MUST NOT rely upon this value being unique, as discussed in Section 5.7. */
  email?: string;
  /**	True if the End-User's e-mail address has been verified; otherwise false. When this Claim Value is true, this means that the OP took affirmative steps to ensure that this e-mail address was controlled by the End-User at the time the verification was performed. The means by which an e-mail address is verified is context-specific, and dependent upon the trust framework or contractual agreements within which the parties are operating. */
  email_verified?: boolean;
  /**	End-User's gender. Values defined by this specification are female and male. Other values MAY be used when neither of the defined values are applicable. */
  gender?: string;
  /**	End-User's birthday, represented as an ISO 8601:2004 [ISO8601‑2004] YYYY-MM-DD format. The year MAY be 0000, indicating that it is omitted. To represent only the year, YYYY format is allowed. Note that depending on the underlying platform's date related function, providing just year can result in varying month and day, so the implementers need to take this factor into account to correctly process the dates. */
  birthdate?: string;
  /**	String from zoneinfo [zoneinfo] time zone database representing the End-User's time zone. For example, Europe/Paris or America/Los_Angeles. */
  zoneinfo?: string;
  /**	End-User's locale, represented as a BCP47 [RFC5646] language tag. This is typically an ISO 639-1 Alpha-2 [ISO639‑1] language code in lowercase and an ISO 3166-1 Alpha-2 [ISO3166‑1] country code in uppercase, separated by a dash. For example, en-US or fr-CA. As a compatibility note, some implementations have used an underscore as the separator rather than a dash, for example, en_US; Relying Parties MAY choose to accept this locale syntax as well. */
  locale?: string;
  /**	End-User's preferred telephone number. E.164 [E.164] is RECOMMENDED as the format of this Claim, for example, +1 (425) 555-1212 or +56 (2) 687 2400. If the phone number contains an extension, it is RECOMMENDED that the extension be represented using the RFC 3966 [RFC3966] extension syntax, for example, +1 (604) 555-1234;ext=5678. */
  phone_number?: string;
  /**	True if the End-User's phone number has been verified; otherwise false. When this Claim Value is true, this means that the OP took affirmative steps to ensure that this phone number was controlled by the End-User at the time the verification was performed. The means by which a phone number is verified is context-specific, and dependent upon the trust framework or contractual agreements within which the parties are operating. When true, the phone_number Claim MUST be in E.164 format and any extensions MUST be represented in RFC 3966 format. */
  phone_number_verified?: boolean;
  /** End-User's preferred postal address. The value of the address member is a JSON [RFC4627] structure containing some or all of the members defined in Section 5.1.1. */
  address?: object;
  /**	Time the End-User's information was last updated. Its value is a JSON number representing the number of seconds from 1970-01-01T0:0:0Z as measured in UTC until the date/time. */
  updated_at?: number;
  /** Session Identifier */
  sid?: string;
  auth_time?: number;
}

export function extractSingleName(humanName: R4.IHumanName): string {
  if (humanName.text) {
    return humanName.text;
  } else {
    const prefixes = humanName.prefix && humanName.prefix.join(" ");
    const givens = humanName.given && humanName.given.join(" ");
    const family = humanName.family || null;
    const suffixes = humanName.suffix && humanName.suffix.join(" ");

    const nameComponents: string[] = [];
    if (prefixes) {
      nameComponents.push(prefixes);
    }
    if (givens) {
      nameComponents.push(givens);
    }
    if (family) {
      nameComponents.push(family);
    }
    if (suffixes) {
      nameComponents.push(suffixes);
    }

    if (nameComponents.length > 0) {
      return nameComponents.join(" ");
    }
  }

  return "";
}

export function extractSingleAddress(address: R4.IAddress): string {
  if (address.text) return address.text!;

  const components: string[] = [];

  if (address.line) {
    components.push(address.line.join(", "));
  }

  if (address.postalCode) {
    components.push(address.postalCode);
  }

  if (address.city) {
    components.push(address.city);
  }

  if (address.district) {
    components.push(address.district);
  }

  if (address.state) {
    components.push(address.state);
  }

  if (address.country) {
    components.push(address.country);
  }

  return components.join(", ");
}

export function getAuthorSpecialities(entry: R4.IDocumentReference): string[] {
  const referenceIDs: string[] | undefined =
    entry.author &&
    entry.author.map((reference) => {
      return reference.reference!.replace("#", "");
    });

  if (!referenceIDs) {
    return [];
  }

  if (!entry.contained) {
    return [];
  }

  const pracitionerRoles = entry.contained.filter((value) => {
    const id = value.id || "0";
    return referenceIDs.includes(id) && value.resourceType == "PractitionerRole";
  }) as R4.IPractitionerRole[];

  const specialties: string[] = pracitionerRoles
    .flatMap((value) => {
      if (value.specialty && value.specialty.length > 0) {
        return value.specialty
          .flatMap((specialty) => {
            if (specialty.coding && specialty.coding.length > 0) {
              return specialty.coding
                .map((coding) => coding.code)
                .filter((coding) => coding) as string[];
            } else {
              return null;
            }
          })
          .filter((specialty) => specialty) as string[];
      }
      return null;
    })
    .filter((value) => value) as string[];

  return specialties;
}

export function getAuthorString(entry: R4.IDocumentReference): string | null {
  const referenceIDs: string[] | undefined =
    entry.author &&
    entry.author.map((reference) => {
      return reference.reference!.replace("#", "");
    });

  if (!referenceIDs) {
    return null;
  }

  if (!entry.contained) {
    return null;
  }

  const authors = entry.contained.filter((value) => {
    const id = value.id || "0";
    return referenceIDs.includes(id);
  });

  const names = authors
    .map((author) => {
      switch (author.resourceType) {
        case "PractitionerRole":
          if (author.practitioner?.reference) {
            const pract = entry.contained!.find(
              (value) => value.id == author.practitioner?.reference?.replace("#", "")
            ) as R4.IPractitioner | undefined;

            const org = entry.contained!.find(
              (value) => value.id == author.organization?.reference?.replace("#", "")
            ) as R4.IOrganization | undefined;

            if (pract) {
              const name = practitionerName(pract);

              if (name && org?.name) return `${name} (${org?.name})`;
              else if (!name && org?.name) return org.name;
              else if (name) return name;
            }
          }
          return null;
        case "Practitioner":
          return practitionerName(author);
        default:
          return null;
      }
    })
    .filter((author) => author) as string[];

  if (names.length > 0) {
    return names.join(" | ");
  }
  return null;
}

function practitionerName(practitioner: R4.IPractitioner): string | null {
  if (practitioner.name && practitioner.name.length > 0) {
    const humanName = practitioner.name[0];
    if (humanName.text) {
      return humanName.text;
    } else {
      const prefixes = humanName.prefix && humanName.prefix.join(" ");
      const givens = humanName.given && humanName.given.join(" ");
      const family = humanName.family || null;
      const suffixes = humanName.suffix && humanName.suffix.join(" ");

      const nameComponents: string[] = [];
      if (prefixes) {
        nameComponents.push(prefixes);
      }
      if (givens) {
        nameComponents.push(givens);
      }
      if (family) {
        nameComponents.push(family);
      }
      if (suffixes) {
        nameComponents.push(suffixes);
      }

      if (nameComponents.length > 0) {
        return nameComponents.join(" ");
      }
    }
  }
  return null;
}

export function uploadedByRoles(document: IDocumentReferenceExtended): string[] | null {
  const originalProviderRole = document.extension?.find(
    (ext) =>
      ext.url == "http://fhir.ch/ig/ch-epr-mhealth/StructureDefinition/ch-ext-author-authorrole"
  )?.valueCoding;

  if (originalProviderRole?.code) return [originalProviderRole.code!];

  const submissionSet = document.originalSubmissionSet || document.submissionSet;
  const submissionSetRoles = submissionSet?.extension?.find(
    (ext) =>
      ext.url == "http://fhir.ch/ig/ch-epr-mhealth/StructureDefinition/ch-ext-author-authorrole"
  )?.valueCoding;

  if (submissionSetRoles?.code) return [submissionSetRoles.code!];

  return null;
}

/** Relationship Tree Structure */
export class DirectNodeChild {
  title: string;
  date: string;
  id: string;

  constructor(title: string, date: string, id: string) {
    this.title = title;
    this.date = date;
    this.id = id;
  }
}

class Node {
  id: string;
  parent: string | null;
  type: R4.DocumentReference_RelatesToCodeKind | null;
  children: { id: string; type: R4.DocumentReference_RelatesToCodeKind; addendumNumber: number }[];
  title: string;
  date: string;
  directChild?: DirectNodeChild = undefined;

  constructor(
    id: string,
    parent: string | null,
    type: R4.DocumentReference_RelatesToCodeKind | null,
    title: string,
    date: string
  ) {
    this.id = id;
    this.parent = parent;
    this.type = type;
    this.children = [];
    this.title = title;
    this.date = date;
  }
}

interface ITree {
  nodes: Map<string, Node>;

  getLeaves(): Node[];
  getHierarchy(forID: string): string[];
  getVersion(forID: string): Number | null;
}

export class Tree implements ITree {
  nodes: Map<string, Node>;
  private leaves: Node[] | null;

  constructor(documents: IDocumentReferenceExtended[] | null) {
    this.nodes = new Map<string, Node>();
    this.leaves = null;

    if (documents) {
      for (const doc of documents) {
        const id = doc.homeCommunityId + "-" + doc.id!;

        const newNode = new Node(
          id,
          null,
          null,
          doc.content[0].attachment.title || "",
          doc.content[0].attachment.creation || ""
        );
        this.nodes.set(id, newNode);
      }
      for (const doc of documents) {
        this.computeRelationship(doc);
      }
      this.computeLeaves();
    }
  }

  private computeRelationship(document: IDocumentReferenceExtended) {
    let parent: string | null = null;
    let type: R4.DocumentReference_RelatesToCodeKind | null = null;
    if (document.relatesTo && document.relatesTo.length > 0) {
      const parentID = document!.relatesTo![0].target.reference || null;
      type = document!.relatesTo![0].code!;
      if (parentID) {
        parent = document.homeCommunityId + "-" + parentID.replace("DocumentReference/", "");
      }
    }

    const id = document.homeCommunityId + "-" + document.id!;

    // currentNode.type = type;
    const currentNode = this.nodes.get(id)!;
    currentNode.parent = parent;

    if (currentNode.parent) {
      const parentNode = this.nodes.get(currentNode.parent!);
      if (parentNode) {
        parentNode.type = type;
        let addendumNumber = 1;
        if (type === R4.DocumentReference_RelatesToCodeKind._appends) {
          addendumNumber = parentNode.children?.slice(-1)[0]?.addendumNumber + 1 || 2;
        }
        parentNode.children.push({ id: currentNode.id, type: type!, addendumNumber });
        parentNode.directChild = {
          title: currentNode.title,
          date: currentNode.date,
          id: currentNode.id,
        };
      }
    }
  }

  private computeLeaves() {
    this.leaves = [];
    this.nodes.forEach((node) => {
      if (node.children.length <= 0) {
        this.leaves!.push(node);
      }
    });
  }

  getLeaves(): Node[] {
    if (this.leaves) {
      return this.leaves;
    } else {
      this.computeLeaves();
      return this.leaves!;
    }
  }

  getHierarchy(forID: string): string[] {
    const node = this.nodes.get(forID);

    if (node) {
      const parents = this.getParents(node).reverse();
      const parentIds = parents.map((parent) => parent.id);
      const parentChildrenIds: string[] = [];
      for (const parent of parents) {
        parentChildrenIds.push(...parent.children.map((child) => child.id));
      }

      const children = this.getChildren(node).map((n) => n.id);
      const parentHierarchy: string[] = [];
      for (const parent of parents) {
        parentHierarchy.push(...this.getHierarchy(parent.id));
      }

      return Array.from(
        new Set([...parentIds, ...parentHierarchy, ...parentChildrenIds, node.id, ...children])
      );
    }

    return [];
  }

  getParents(node: Node): Node[] {
    if (node.parent) {
      const parent = this.nodes.get(node.parent);
      if (parent) {
        return [parent].concat(this.getParents(parent));
      } else {
        return [];
      }
    } else {
      return [];
    }
  }

  private getChildren(node: Node): Node[] {
    if (node.children.length > 0) {
      const ret = [];

      for (const { id } of node.children) {
        const child = this.nodes.get(id);
        ret.push(child!);
        ret.push(...this.getChildren(child!));
      }
      return ret;
    } else {
      return [];
    }
  }

  getVersion(forID: string): number | null {
    const node = this.nodes.get(forID);
    if (node) {
      const parents = this.getParents(node);
      return (
        parents.filter(
          (parent) =>
            parent.type === R4.DocumentReference_RelatesToCodeKind._replaces &&
            !parent.children.find(
              (child) =>
                child.type === R4.DocumentReference_RelatesToCodeKind._appends && child.id === forID
            )
        ).length + 1
      );
    }
    return null;
  }

  getAddendumNumber(forID: string): number | null {
    const node = this.nodes.get(forID);
    if (node) {
      if (node.parent) {
        const parent = this.nodes.get(node.parent);

        return parent?.children.find((child) => child.id === forID)?.addendumNumber || 1;
      } else {
        return 1;
      }
    }
    return null;
  }

  /*
  private visualize() {
    for (const leaf of this.getLeaves()) {
      console./log(`${leaf.title}`);
      if (leaf.parent) {
        this.visualizeParent(leaf.parent);
      }
    }
  }

  private visualizeParent(parentID: string, indentationLevel: number = 1) {
    const parent = this.nodes.get(parentID)!;
    const tabs = "\t".repeat(indentationLevel);
    if (parent.type) {
      console./log(`${tabs}${parent.title} (${parent.type})`);
    } else {
      console./log(`${tabs}${parent.title}`);
    }

    if (parent.parent) {
      this.visualizeParent(parent.parent, indentationLevel + 1);
    }
  }
  */
}
/** END Relationship Tree Structure */

/**
 * This Interface describes an object that contains the information
 * to enforce the edit of metadata based on documentStatus, Uploader,
 * community origin, and portal role.
 *
 * @interface IMetadataEditTable
 */
export interface IMetadataEditTable {
  authorDisabled: boolean;
  availabilityStatusDisabled: boolean;
  classCodeDisabled: boolean;
  commentDisabled: boolean;
  confidentialityCodeDisabled: boolean;
  creationTimeDisabled: boolean;
  deletionStatusDisabled: boolean;
  eventCodeDisabled: boolean;
  formatCodeDisabled: boolean;
  healthcareFacilityTypeCodeDisabled: boolean;
  languageCodeDisabled: boolean;
  mimeTypeDisabled: boolean;
  practiceSettingCodeDisabled: boolean;
  serviceTimeDisabled: boolean;
  patientGenderDisabled: boolean;
  titleDisabled: boolean;
  typeCodeDisabled: boolean;
}

export class MetadataEditEnforcer {
  private document: IDocumentReferenceExtended;
  /**
   * The Portal role (HCP or PAT), not the actual current role.
   *
   * @private
   * @type {Role}
   * @memberof MetadataEditEnforcer
   */
  private usersRole: Role;
  /**
   * The IDToken Subject in case the current user is a HCP.
   * Needed to check against the DocumentManifest.author
   *
   * @private
   * @type {string}
   * @memberof MetadataEditEnforcer
   */
  private idTokenSub?: string;
  /**
   * The system to filter for authorRoles by.
   *
   * @private
   * @type {string}
   * @memberof MetadataEditEnforcer
   */
  private authorRoleSystem: string;
  /**
   * The Home Community ID of this user
   *
   * @private
   * @type {string}
   * @memberof MetadataEditEnforcer
   */
  private homeCommunityID: string;

  constructor(
    usersRole: Role,
    document: IDocumentReferenceExtended,
    authorRoleSystem: string,
    homeCommunityID: string,
    idTokenSub?: string
  ) {
    this.usersRole = usersRole;
    this.document = document;
    this.authorRoleSystem = authorRoleSystem;
    this.homeCommunityID = homeCommunityID;
    this.idTokenSub = idTokenSub;
  }

  get applicableMetadataEditTable(): IMetadataEditTable {
    switch (this.usersRole) {
      case Role.Patient:
      case Role.Representative:
        if (this.documentIsInHomeCommunity) {
          // Home Community
          if (this.uploadedByPatient) {
            // Uploaded by PAT
            return this.Patient_HomeCommunity_PAT;
          } else if (this.uploadedByAnyHCP) {
            // Uploaded by HCP
            return this.Patient_HomeCommunity_HCP;
          } else {
            // ELSE
            return this.DefaultForPAT;
          }
        } else {
          // Cross Community
          if (this.documentIsDeprecated) {
            // Deprectated
            return this.DefaultForPAT;
          } else {
            // Approved
            if (this.uploadedByAnyHCP) {
              // Uploaded by HCP
              return this.Patient_CrossCommunity_Approved;
            } else {
              // NOT Uploaded by HCP
              return this.DefaultForPAT;
            }
          }
        }
      case Role.HealthcareProfessional:
      case Role.Assistant:
        if (this.documentIsInHomeCommunity) {
          // Home Community
          if (this.uploadedByAnyHCP) {
            // Uploaded by ANY HCP
            return this.HCP_HomeCommunity_HCP;
          } else {
            // Uploaded by PAT
            return this.DenyAll;
          }
        } else {
          // Cross Community
          return this.DenyAll;
        }
    }

    return this.DenyAll;
  }

  get documentIsInHomeCommunity(): boolean {
    if (this.document.extension) {
      const documentHomeCommunity = this.document.extension.find((extension: R4.IExtension) => {
        return (
          extension.url &&
          extension.url == "https://api.phellowseven.com/fhir/StructureDefinition/homeCommunityId"
        );
      }) as R4.IExtension;

      if (documentHomeCommunity && documentHomeCommunity.valueString) {
        return documentHomeCommunity.valueString?.replace("urn:oid:", "") == this.homeCommunityID;
      }
    }
    return true;
  }

  get documentIsDeprecated(): boolean {
    return this.document.status != R4.DocumentReferenceStatusKind._current;
  }

  ////////////////////////
  // MetadataEditTables //
  ////////////////////////

  /**
   * The Patient wants to edit an approved/deprecated document in their home community that they uploaded themselves.
   *
   * @readonly
   * @private
   * @type {IMetadataEditTable}
   * @memberof MetadataEditEnforcer
   */
  private get Patient_HomeCommunity_PAT(): IMetadataEditTable {
    return {
      authorDisabled: false,
      availabilityStatusDisabled: false,
      classCodeDisabled: false,
      commentDisabled: false,
      confidentialityCodeDisabled: false,
      creationTimeDisabled: true,
      deletionStatusDisabled: false,
      eventCodeDisabled: false,
      formatCodeDisabled: false,
      healthcareFacilityTypeCodeDisabled: false,
      languageCodeDisabled: false,
      mimeTypeDisabled: false,
      practiceSettingCodeDisabled: false,
      serviceTimeDisabled: false,
      patientGenderDisabled: false,
      titleDisabled: false,
      typeCodeDisabled: false,
    };
  }

  /**
   * The Patient wants to edit an approved/deprecated document in their home community that a HCP uploaded.
   *
   * @readonly
   * @private
   * @type {IMetadataEditTable}
   * @memberof MetadataEditEnforcer
   */
  private get Patient_HomeCommunity_HCP(): IMetadataEditTable {
    return {
      authorDisabled: true,
      availabilityStatusDisabled: true,
      classCodeDisabled: true,
      commentDisabled: true,
      confidentialityCodeDisabled: false,
      creationTimeDisabled: true,
      deletionStatusDisabled: false,
      eventCodeDisabled: true,
      formatCodeDisabled: true,
      healthcareFacilityTypeCodeDisabled: true,
      languageCodeDisabled: true,
      mimeTypeDisabled: true,
      practiceSettingCodeDisabled: true,
      serviceTimeDisabled: true,
      patientGenderDisabled: true,
      titleDisabled: true,
      typeCodeDisabled: true,
    };
  }

  /**
   * The Patient wants to edit an approved document cross community that a HCP uploaded.
   *
   * @readonly
   * @private
   * @type {IMetadataEditTable}
   * @memberof MetadataEditEnforcer
   */
  private get Patient_CrossCommunity_Approved(): IMetadataEditTable {
    return {
      authorDisabled: true,
      availabilityStatusDisabled: true,
      classCodeDisabled: true,
      commentDisabled: true,
      confidentialityCodeDisabled: false,
      creationTimeDisabled: true,
      deletionStatusDisabled: false,
      eventCodeDisabled: true,
      formatCodeDisabled: true,
      healthcareFacilityTypeCodeDisabled: true,
      languageCodeDisabled: true,
      mimeTypeDisabled: true,
      practiceSettingCodeDisabled: true,
      serviceTimeDisabled: true,
      patientGenderDisabled: true,
      titleDisabled: true,
      typeCodeDisabled: true,
    };
  }

  /**
   * The HCP wants to edit an approved/deprecated document in their home community that they themselves uploaded.
   *
   * @readonly
   * @private
   * @type {IMetadataEditTable}
   * @memberof MetadataEditEnforcer
   */
  private get HCP_HomeCommunity_HCP(): IMetadataEditTable {
    return {
      authorDisabled: false,
      availabilityStatusDisabled: false,
      classCodeDisabled: false,
      commentDisabled: false,
      confidentialityCodeDisabled: true,
      creationTimeDisabled: true,
      deletionStatusDisabled: true,
      eventCodeDisabled: false,
      formatCodeDisabled: false,
      healthcareFacilityTypeCodeDisabled: false,
      languageCodeDisabled: false,
      mimeTypeDisabled: false,
      practiceSettingCodeDisabled: false,
      serviceTimeDisabled: false,
      patientGenderDisabled: false,
      titleDisabled: false,
      typeCodeDisabled: false,
    };
  }

  /**
   * No edits at all are allowed.
   *
   * @readonly
   * @private
   * @type {IMetadataEditTable}
   * @memberof MetadataEditEnforcer
   */
  private get DenyAll(): IMetadataEditTable {
    return {
      authorDisabled: true,
      availabilityStatusDisabled: true,
      classCodeDisabled: true,
      commentDisabled: true,
      confidentialityCodeDisabled: true,
      creationTimeDisabled: true,
      deletionStatusDisabled: true,
      eventCodeDisabled: true,
      formatCodeDisabled: true,
      healthcareFacilityTypeCodeDisabled: true,
      languageCodeDisabled: true,
      mimeTypeDisabled: true,
      practiceSettingCodeDisabled: true,
      serviceTimeDisabled: true,
      patientGenderDisabled: true,
      titleDisabled: true,
      typeCodeDisabled: true,
    };
  }

  /**
   * No edits at all are allowed.
   *
   * @readonly
   * @private
   * @type {IMetadataEditTable}
   * @memberof MetadataEditEnforcer
   */
  private get DefaultForPAT(): IMetadataEditTable {
    return {
      authorDisabled: true,
      availabilityStatusDisabled: true,
      classCodeDisabled: true,
      commentDisabled: true,
      confidentialityCodeDisabled: false,
      creationTimeDisabled: true,
      deletionStatusDisabled: false,
      eventCodeDisabled: true,
      formatCodeDisabled: true,
      healthcareFacilityTypeCodeDisabled: true,
      languageCodeDisabled: true,
      mimeTypeDisabled: true,
      practiceSettingCodeDisabled: true,
      serviceTimeDisabled: true,
      patientGenderDisabled: true,
      titleDisabled: true,
      typeCodeDisabled: true,
    };
  }

  public static get AllowAll(): IMetadataEditTable {
    return {
      authorDisabled: false,
      availabilityStatusDisabled: false,
      classCodeDisabled: false,
      commentDisabled: false,
      confidentialityCodeDisabled: false,
      creationTimeDisabled: false,
      deletionStatusDisabled: false,
      eventCodeDisabled: false,
      formatCodeDisabled: false,
      healthcareFacilityTypeCodeDisabled: false,
      languageCodeDisabled: false,
      mimeTypeDisabled: false,
      practiceSettingCodeDisabled: false,
      serviceTimeDisabled: false,
      patientGenderDisabled: false,
      titleDisabled: false,
      typeCodeDisabled: false,
    };
  }

  ///////////////
  // UTILITIES //
  ///////////////

  private get uploadedByAnyHCP(): boolean {
    if (this.uploadedByRoles) {
      return this.uploadedByRoles.includes(Role.HealthcareProfessional);
    }
    return false;
  }

  private get uploadedByPatient(): boolean {
    if (this.uploadedByRoles) {
      return this.uploadedByRoles.includes(Role.Patient);
    }
    return false;
  }

  private get uploadedByRoles(): string[] | null {
    return uploadedByRoles(this.document);
  }
}
