
import Button from "primevue/button";
import Sidebar from "primevue/sidebar";
import { TranslateResult } from "vue-i18n";
import { Component, Prop, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import AppIcon from "@/components/AppIcon.vue";
import LanguageSelect from "@/components/LanguageSelect.vue";
import LeftPanelCloseIcon from "@/components/icons/LeftPanelCloseIcon.vue";
import LeftPanelOpenIcon from "@/components/icons/LeftPanelOpenIcon.vue";
import MenuContent from "@/components/navigation/MenuContent.vue";
import { Role } from "@/global";
import AccountModule from "@/store/modules/account";
import SidebarModule from "@/store/modules/sidebar";
import ViewModule from "@/store/modules/view";

@Component({
  components: {
    Sidebar,
    MenuContent,
    AppIcon,
    Button,
    LanguageSelect,
    LeftPanelCloseIcon,
    LeftPanelOpenIcon,
  },
})
export default class MenuSidebar extends Vue {
  @Prop({ default: Role.Patient }) private readonly role!: Role;

  private sidebarState = getModule(SidebarModule);
  private accountState = getModule(AccountModule);
  private viewState = getModule(ViewModule);

  get isLoginPage(): boolean {
    return this.$route.path.startsWith("/login");
  }

  setSidebarOpen(open: boolean) {
    this.sidebarState.setIsSidebarOpen(open);
  }

  get toggleIcon(): string {
    if (this.sidebarState.isSidebarOpen && this.viewState.isSmallScreen) {
      return "pi pi-times";
    } else if (this.sidebarState.isSidebarOpen) {
      return "pi pi-angle-double-left";
    } else {
      return "pi pi-bars";
    }
  }

  get title(): TranslateResult {
    /* eslint-disable no-fallthrough */
    switch (this.role) {
      case Role.Representative:
      // return this.$t("navbar.titleRepresentative");
      case Role.Patient:
        return this.$t("navbar.titlePatient");
      case Role.Assistant:
      // return this.$t("navbar.titleAssistant");
      case Role.HealthcareProfessional:
        return this.$t("navbar.titleHealthcareprofessional");
      default:
        return "";
    }
    /* eslint-enable no-fallthrough */
  }

  get sidebarVisible(): boolean {
    if (!this.accountState.parsedIDToken) return false;

    if (this.viewState.isSmallScreen) {
      return this.sidebarState.isSidebarOpen;
    }

    return true;
  }

  set sidebarVisible(value: boolean) {
    this.sidebarState.setIsSidebarOpen(value);
  }
}
