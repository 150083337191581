import { debounce } from "lodash";
import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import AccountModule from "@/store/modules/account";

const accountState = getModule(AccountModule);

const debouncedUpdate = debounce(async () => {
  await accountState.updateSessionTimeout();
}, 1000);

@Component({})
export default class SessionMixin extends Vue {
  mounted() {
    if (this.$route.meta?.authorize) debouncedUpdate();
  }
}
