import { render, staticRenderFns } from "./LanguageSelect.vue?vue&type=template&id=676fe0f4&scoped=true"
import script from "./LanguageSelect.vue?vue&type=script&lang=ts"
export * from "./LanguageSelect.vue?vue&type=script&lang=ts"
import style0 from "./LanguageSelect.vue?vue&type=style&index=0&id=676fe0f4&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "676fe0f4",
  null
  
)

export default component.exports